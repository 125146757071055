import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import React, {Component} from "react";
import i18n, {getCurrentLanguage} from '../translations/config/i18n';

export class MapContainer extends Component {

    render() {

        const mapStyle = {
            height: '218px',
            width: '99%',
            position: 'relative',
            margin: '2px 2px 2px 2px',
        };

        return (
            <Map
                google={this.props.google}
                style={mapStyle}
                initialCenter={this.props.initialCenter}
                zoom={3}
            >
                <Marker/>
            </Map>
        );
    }
}

const loadingContainer = () => (
    <div>{i18n.t('common:map.loading')}</div>
);

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCG_QhTdWaFeJOuh0A5giE78HbhoOJ-Bf4',
    language: getCurrentLanguage() ? getCurrentLanguage() : 'en',
    LoadingContainer: loadingContainer
})(MapContainer)
