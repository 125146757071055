import { GET } from "./methods";

class Route {
    constructor(method, url, errors = []) {
        this.method = method;
        this.url = url;
        this.errors = errors;
    }
}

const getLiveAnimalData = (animalId) => new Route(GET, `api/qrs/${animalId}`);

export const animalRoutes = {
    getLiveAnimalData: (animalId) => getLiveAnimalData(animalId)
};