import {combineReducers, applyMiddleware, createStore} from 'redux';
import {uiReducer, animalReducer} from './redux/reducers/index';
import {animal} from './redux/middlewares/index';
import logger from 'redux-logger';

const reducer = combineReducers({
    ui: uiReducer,
    animal: animalReducer
});

const middleware = applyMiddleware(
    logger,
    animal
);
const store = createStore(reducer, middleware);

export default store;