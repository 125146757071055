import React from 'react';
import {withTranslation} from 'react-i18next'
import PropTypes from "prop-types";
import Map from '../components/Map'
import moment from 'moment';

function formatGenderData(gender) {
    gender = gender.toLowerCase()
    if (gender === "notdefined") {
        return "NO DEFINIDO";
    } else if (gender === "male") {
        return "MACHO"
    } else if (gender === "female") {
        return "HEMBRA"
    }
    return gender.toUpperCase();
}

const getCountryFlagImage = (country) => {
    let flagName = undefined;

    switch (country) {
        case "ARGENTINA":
            flagName = 'argentina.svg';
            break;
        case "URUGUAY":
            flagName = 'uruguay.svg';
            break;
        case "BRASIL":
            flagName = 'brazil.svg';
            break;
        case "PARAGUAY":
            flagName = 'paraguay.svg';
            break;
        default:
            break;
    }

    if (flagName) {
        return <img className="country_flag" src={require(`../../assets/icons/country_flags/${flagName}`)}
                    alt={'country flag'}/>
    }
    return null;
};

function formatDate(date) {
    return moment(date, 'YYYY-MM-DD').format('L')
}

function meatCutTable(props) {
    const {data} = props;
    return <div>
        <table>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <strong>{props.t('main_table.labels.product_id')}</strong>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <h4>{data.product_id}</h4>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <h4>{props.t('main_table.labels.meat_cut')}</h4>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <img className="meat_cut" src={data.meat_cut_image_url} alt={'meat cut'}/>
                        <div>{data.meat_cut_name}</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <h4>{props.t('main_table.labels.slaughter_date')}</h4>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <div>{data.slaughter_date}</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <h4>{props.t('main_table.labels.origin')}</h4>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <div className="country_of_origin">
                            <div>{data.country_of_origin}</div>
                            <img className="country_flag" src="http://192.168.64.2/aurora/img/flag-argentina.png"
                                 alt={'country flag'}/>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <h4>{props.t('main_table.labels.specie_breed_category')}</h4>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <div>{data.specie_breed_category}</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <h4>{props.t('main_table.labels.productive_system')}</h4>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <div>{data.productive_system}</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        {/* <h4>{props.t('main_table.labels.establishment_location')}</h4> */}
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <div></div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <h4>{props.t('main_table.labels.brand')}</h4>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <div>
                            <img className="company_logo" src={data.brand_image_url} alt={'company logo'}/>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
}

function animalTable(props) {
    const {data} = props;
    return <div>
        <table>
            <tbody>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <strong>{props.t('live_animal_table.labels.animal_id')}</strong>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <h4>{data.earId}</h4>
                    </div>
                </td>
            </tr>
            {
                data.earImage ?
                    <tr>
                        <td className="colored">
                            <div className="row_content">
                                <h4>{props.t('live_animal_table.labels.earImage')}</h4>
                            </div>
                        </td>
                        <td className="not_colored">
                            <div className="row_content">
                                <img className="meat_cut" src={data.earImage} alt={'meat cut'}/>
                            </div>
                        </td>
                    </tr>
                    : null
            }
            {
                data.motherEarId ?
                    <tr>
                        <td className="colored">
                            <div className="row_content">
                                <h4>{props.t('live_animal_table.labels.motherId')}</h4>
                            </div>
                        </td>
                        <td className="not_colored">
                            <div className="row_content">
                                <div><a href={window.location.origin + "/qrs/" + data.motherId}>{data.motherEarId}</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    : null
            }
            {
                data.fatherEarId ?
                    <tr>
                        <td className="colored">
                            <div className="row_content">
                                <h4>{props.t('live_animal_table.labels.motherId')}</h4>
                            </div>
                        </td>
                        <td className="not_colored">
                            <div className="row_content">
                                <div><a href={window.location.origin + "/qrs/" + data.fatherId}>{data.fatherEarId}</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    : null
            }
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <h4>{props.t('main_table.labels.specie_breed_category')}</h4>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <div>{data.specie.toUpperCase()}{' / '}{data.breed.toUpperCase()}{' / '}{data.category.toUpperCase()}</div>
                    </div>
                </td>
            </tr>
            {
                data.birthDate ?
                    <tr>
                        <td className="colored">
                            <div className="row_content">
                                <h4>{props.t('live_animal_table.labels.birthDate')}</h4>
                            </div>
                        </td>
                        <td className="not_colored">
                            <div className="row_content">
                                <div>{formatDate(data.birthDate)}</div>
                            </div>
                        </td>
                    </tr>
                    : null
            }
            {
                data.birthWeight ?
                    <tr>
                        <td className="colored">
                            <div className="row_content">
                                <h4>{props.t('live_animal_table.labels.birthWeight')}</h4>
                            </div>
                        </td>
                        <td className="not_colored">
                            <div className="row_content">
                                <div>{data.birthWeight}{' kg'}</div>
                            </div>
                        </td>
                    </tr>
                    : null
            }
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <h4>{props.t('live_animal_table.labels.gender')}</h4>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <div>{formatGenderData(data.gender)}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="colored">
                    <div className="row_content">
                        <h4>{props.t('main_table.labels.productive_system')}</h4>
                    </div>
                </td>
                <td className="not_colored">
                    <div className="row_content">
                        <div>{data.productionSystem.toUpperCase()}</div>
                    </div>
                </td>
            </tr>
            {
                data.facilityCountry ?
                    <tr>
                        <td className="colored">
                            <div className="row_content">
                                <h4>{props.t('main_table.labels.origin')}</h4>
                            </div>
                        </td>
                        <td className="not_colored">
                            <div className="row_content">
                                <div className="country_of_origin">
                                    <div>{data.facilityCountry.toUpperCase()}</div>
                                    {getCountryFlagImage(data.facilityCountry.toUpperCase())}
                                </div>
                            </div>
                        </td>
                    </tr>
                    : null
            }
            {
                data.facilityLocation ?
                    <tr>
                        <td className="colored">
                            <div className="row_content">
                                <h4>{props.t('main_table.labels.establishment_location')}</h4>
                            </div>
                        </td>
                        <td className="not_colored">
                            <p className="time-line">
                                {props.t('main_table.labels.productive_facility')}{' '}
                                {data.facilityName.toUpperCase()}
                            </p>
                            <div className="map_row">
                                <Map initialCenter={data.facilityLocation}/>
                            </div>
                        </td>
                    </tr>
                    : null
            }
            {
                data.facilityBrandImage ?
                    <tr>
                        <td className="colored">
                            <div className="row_content">
                                <h4>{props.t('main_table.labels.brand')}</h4>
                            </div>
                        </td>
                        <td className="not_colored">
                            <div className="row_content">
                                <div>
                                    <img className="company_logo" src={data.facilityBrandImage} alt={'company logo'}/>
                                </div>
                            </div>
                        </td>
                    </tr>
                    : null
            }
            <tr>
                <td className="colored" colSpan="2">
                    <div className="history_text">
                        <h4>{props.t('main_table.labels.history')}</h4>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="not_colored" colSpan="2">
                    <div className="history_details">
                        <div>
                            <img className="time-line time-line-logo" src="/images/linea-tiempo-acua.png"
                                 alt={'time line'}/>
                            {
                                data.facilityAirViewImage ?
                                    <img className="time-line time-line-logo" src={data.facilityAirViewImage}
                                         alt={'facility air view'}/>
                                    : null
                            }
                            <p className="time-line time-line-logo text_centered">
                                {props.t('main_table.labels.productive_facility_abbreviation')}
                                {data.facilityName.toUpperCase()}
                            </p>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
}

const Table = (props) => {
    Table.prototype = {
        data: PropTypes.object.isRequired
    };
    if (props.data.liveAnimal) {
        return animalTable(props);
    }
    if (props.data.meatCut) {
        return meatCutTable(props);
    }
    return null;
};

export default withTranslation('common')(Table);