import React from 'react';
import Particles from 'react-particles-js';
import { withTranslation } from "react-i18next";

const particleParams = {
    particles: {
        number: {
            value: 10
        },
        size: {
            value: 3
        }
    },
    interactivity: {
        events: {
            onhover: {
                enable: true,
                mode: "repulse"
            }
        }
    }
};

const particleStyle = {
    backgroundImage: "url(/images/top_bar_background.jpg)",
};

const Topbar = (props) => {
    const { t } = props;

    return (
        <div className="top_bar">
            <Particles height="76px" style={particleStyle} params={particleParams}/>
            <div className="top_bar_container" >
              <div className="top_bar_text">{t('top_bar.title')}</div>
              <img id="cv_top_bar_logo" src="/images/carnes_validadas_logo.png" alt={'logo'}/>
            </div>
        </div>
    )
};

export default withTranslation('common')(Topbar)