import React from 'react';

const CowLoader = () => {
    return (
        <div className="loading">
            <img alt="cow" src={process.env.PUBLIC_URL + `images/cow-transparent.gif`}/>
        </div>
    )
};

export default CowLoader;