import { GET_LIVE_ANIMAL_DATA } from '../actions/animalActions';
import actions from '../actions';
import api from '../../api/index';

const animal = ({dispatch}) => next => action => {
    next(action);
    switch (action.type) {
        case GET_LIVE_ANIMAL_DATA:
            api.getLiveAnimalData(action.animalId)
                .then(res => {
                    dispatch(actions.animal.getLiveAnimalDataResponse(res))
                })
                .catch(err => {
                    dispatch(actions.animal.getLiveAnimalDataError(err))
                });
            break;
        default:
    }
};

export default animal;