import React from 'react';
import './App.css';
import './scss/app.scss';
import MainWrapper from '../src/containers/MainWrapper';
import { I18nextProvider } from 'react-i18next';
import i18n from './translations/config/i18n';
import { Provider } from 'react-redux';
import store from './store';

function App() {
  return (
      <Provider store={store}>
          <I18nextProvider i18n={i18n}>
              <MainWrapper/>
          </I18nextProvider>
      </Provider>
  );
}

export default App;
