import settings from '../util/settings';
import { POST, GET, PUT, DELETE } from '../util/routes/methods';
import { TIMEOUT } from "../util/routes/errors";
import { animalRoutes } from "../util/routes/routes";

const baseUrl = settings.baseUrl;

const performRequest = (route, {body, headers} = {}) => {
    let promise;
    switch (route.method) {
        case POST:
            throw Error(`Post not implemented`);
        case GET:
            promise = getRequest(baseUrl, route.url, headers);
            break;
        case PUT:
            throw Error(`Post not implemented`);
        case DELETE:
            throw Error(`Post not implemented`);
        default:
            throw Error(`Invalid method ${route.method}`);
    }

    const withTimeoutPromise = Promise.race([
        promise,
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error(TIMEOUT)), settings.timeout)
        )
    ]);

    return withTimeoutPromise
        .then((res) => {
            if(res.ok) return res.json();
            // If res is NOT ok, is an error.
            return res.json()
                .then((e) => {
                    const error = {statusCode: res.status, errorCode: e.code};
                    throw error;
                });
        })
        .catch(error => {
            if(error.message === TIMEOUT) throw new Error(TIMEOUT);
            //throw route.parseError(error);
            throw error;
        });
};

const getRequest = (baseUrl, url, headers) => {
    return fetch(baseUrl + url, {headers})
};

/* ~~~~~~~~~~~~~~~~~~ Api Production Requests ~~~~~~~~~~~~~~~~~~ */
export function getLiveAnimalData(animalId) {
    return performRequest(animalRoutes.getLiveAnimalData(animalId))
}