export const GET_LIVE_ANIMAL_DATA = 'GET_LIVE_ANIMAL_DATA';
export const GET_LIVE_ANIMAL_DATA_RESPONSE = 'GET_LIVE_ANIMAL_DATA_RESPONSE';
export const GET_LIVE_ANIMAL_DATA_ERROR = 'GET_LIVE_ANIMAL_DATA_ERROR';

const animalActions = {
    getLiveAnimalData(animalId) {
        return {
            type: GET_LIVE_ANIMAL_DATA,
            animalId
        }
    },
    getLiveAnimalDataResponse(response) {
        return {
            type: GET_LIVE_ANIMAL_DATA_RESPONSE,
            response
        }
    },
    getLiveAnimalDataError(message) {
        return {
            type: GET_LIVE_ANIMAL_DATA_ERROR,
            message
        }
    }
};

export default animalActions;