import React, { Component }  from "react";
import Table from "../components/Table";
import Topbar from "./Topbar/Topbar";
import Footer from "./Footer/Footer";
import moment from "moment";
import AlertIcon from 'mdi-react/AlertIcon';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
import actions from '../redux/actions/index';
import CowLoader from '../components/CowLoader';

export class MainWrapper extends Component {

    constructor(props) {
        super(props);
        moment.locale('es');
    }

    componentDidMount() {
        document.title = "CV-Traza";
        const url = (window.location.href).split('/');
        const idFromUrl = url.pop() || url.pop();
        this.props.getLiveAnimalData(idFromUrl);
    }

    render() {
        return (
            <div className="main_wrapper">
                <Topbar />
                <div className={`container ${!this.props.liveAnimalDetails ? 'no_data' : ''}`}>
                    <div className="main_table">
                        {
                            this.props.getLiveAnimalDetailsPending ?
                                <CowLoader/>:
                                this.props.liveAnimalDetails ?
                                    <Table data={this.props.liveAnimalDetails} />:
                                    <div className="no_data_container">
                                        <AlertIcon/>
                                        <h5 style={{marginLeft: '10px'}}>{this.props.t('no_data')}</h5>
                                    </div>
                        }
                    </div>
                </div>
               {/* <Footer />*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    liveAnimalDetails:  state.animal.liveAnimalDetails,
    getLiveAnimalDetailsPending: state.ui.getLiveAnimalDetailsPending,
    getLiveAnimalDetailsError: state.ui.getLiveAnimalDetailsError,
});

const mapDispatchToProps = (dispatch) => ({
    getLiveAnimalData: (animalId) => dispatch(actions.animal.getLiveAnimalData(animalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(MainWrapper));