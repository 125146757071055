import {
    GET_LIVE_ANIMAL_DATA,
    GET_LIVE_ANIMAL_DATA_RESPONSE,
    GET_LIVE_ANIMAL_DATA_ERROR
} from "../actions/animalActions";

const initialState = {
    getLiveAnimalDetailsPending: false,
    getLiveAnimalDetailsError: false,
    getLiveAnimalDetailsErrorMessage: '',
};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIVE_ANIMAL_DATA:
            return {
                ...state,
                getLiveAnimalDetailsPending: true,
                getLiveAnimalDetailsError: false,
                getLiveAnimalDetailsErrorMessage: ''
            };
        case GET_LIVE_ANIMAL_DATA_RESPONSE:
            return {
                ...state,
                getLiveAnimalDetailsPending: false,
                getLiveAnimalDetailsError: false,
                getLiveAnimalDetailsErrorMessage: ''
            };
        case GET_LIVE_ANIMAL_DATA_ERROR:
            return {
                ...state,
                getLiveAnimalDetailsPending: false,
                getLiveAnimalDetailsError: true,
                getLiveAnimalDetailsErrorMessage: action.message
            };
        default:
            return {...state}
    }
};

export default uiReducer;