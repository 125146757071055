import {
    GET_LIVE_ANIMAL_DATA_RESPONSE,
} from "../actions/animalActions";

const initialState = {
    liveAnimalDetails: undefined
};

const animalReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_LIVE_ANIMAL_DATA_RESPONSE:
            return {...state, liveAnimalDetails: action.response};
        default:
            return {...state}
    }
};

export default animalReducer;